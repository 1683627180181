import { useMemo } from 'react';

import { ChartStat, ChartStats, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { ComparisonResult } from 'src/types/shared/Comparison';

const useTopicStatsForCompareChart = (
    topicsForAssessmentChart: ChartTopic[],
    compareResults: ComparisonResult[],
) => {
    const topicStatsForCompareChart: ChartStats[] = useMemo(
        () =>
            [...topicsForAssessmentChart]
                .sort((a, b) => a.sort - b.sort)
                .map(topic => {
                    const stats: ChartStat[] = compareResults.map(result => {
                        const relatedCompareTopic = result.topicResults.find(
                            t => t.id === topic.id,
                        );

                        const percentage = relatedCompareTopic?.percentage || 0;

                        return {
                            name: result.friendlyName,
                            percentage: +percentage.toFixed(0),
                        };
                    });

                    return {
                        stats,
                    };
                }),
        [topicsForAssessmentChart, compareResults],
    );

    return topicStatsForCompareChart;
};

export default useTopicStatsForCompareChart;
