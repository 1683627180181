import useFetchTopicsBehaviors from '@pages/entities/entity/hooks/useFetchTopicsBehaviors';
import useLeadersComparisonFetch from './hooks/useLeadersComparisonFetch';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Meta from 'lib/src/components/meta/Meta';
import Title from 'lib/src/components/typography/Title';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import ComparisonChart from '@components/compare/ComparisonChart';

const LeadersComparison: React.FC = () => {
    const {
        topicsBehaviors,
        isFetching: isFetchingTopicsBehaviors,
        fetchError: topicBehaviorsError,
    } = useFetchTopicsBehaviors();
    const {
        anchorLeader,
        comparisonLeaders,
        isFetching: isFetchingComparison,
        error: comparisonError,
    } = useLeadersComparisonFetch();

    const title = `Leaders Comparison${anchorLeader ? ` for ${anchorLeader.friendlyName}` : ''}`;

    return (
        <>
            <Meta title={title} />

            <CreateHeader>
                <Title>{title}</Title>
            </CreateHeader>

            <DataCheck
                isEmpty={!topicsBehaviors || !anchorLeader || !comparisonLeaders}
                isLoading={isFetchingTopicsBehaviors || isFetchingComparison}
                error={topicBehaviorsError || comparisonError}
            >
                {topicsBehaviors && anchorLeader && comparisonLeaders && (
                    <ComparisonChart
                        topicsBehaviours={topicsBehaviors}
                        anchor={anchorLeader}
                        comparison={comparisonLeaders}
                    />
                )}
            </DataCheck>
        </>
    );
};

export default LeadersComparison;
