import { RootState } from '@reducers/index';
import { AnchorResult, ComparisonResult } from 'src/types/shared/Comparison';
import { Leader } from 'src/types/shared/LeaderSearch';

export const getLeadersSearch = (state: RootState): Record<number, Leader> =>
    state.leadersReducer.leaders;
export const getLeadersIsPosting = (state: RootState): boolean => state.leadersReducer.isPosting;
export const getLeadersSearchError = (state: RootState): string | null =>
    state.leadersReducer.postError;

export const getAnchorLeader = (state: RootState): AnchorResult | null =>
    state.leadersReducer.anchorResult;
export const getComparisonLeaders = (state: RootState): ComparisonResult[] | null =>
    state.leadersReducer.comparisonResults;
export const getLeadersComparisonIsFetching = (state: RootState): boolean =>
    state.leadersReducer.isFetchingComparison;
export const getLeadersComparisonError = (state: RootState): string | null =>
    state.leadersReducer.fetchComparisonError;
