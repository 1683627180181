import { useMemo, useState } from 'react';
import qs from 'qs';

import { Leader } from 'src/types/shared/LeaderSearch';

const useLeadersSelection = () => {
    const [selected, setSelected] = useState<Leader[]>([]);

    const isSelected = (leaderID: number) => {
        if (selected.map(curLeader => curLeader.id).includes(leaderID)) {
            return true;
        }

        return false;
    };

    const updateSelection = (leader: Leader) => {
        const isLeaderSelected = isSelected(leader.id);

        if (isLeaderSelected) {
            const newSelectedValue = selected.filter(item => item.id !== leader.id);
            setSelected(newSelectedValue);
        } else {
            const newSelectedValue = [...selected, leader];
            setSelected(newSelectedValue);
        }
    };

    const isMaxedOut = selected.length > 3;

    const comparisonLink = useMemo((): string => {
        const link = '/leader-search/compare';
        const paramsData = {
            ids: selected.map(leader => leader.id + ''),
        };

        const params = qs.stringify(paramsData, { arrayFormat: 'repeat' });
        return `${link}?${params}`;
    }, [selected]);

    return { selected, isSelected, updateSelection, isMaxedOut, comparisonLink };
};

export default useLeadersSelection;
